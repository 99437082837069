import React from 'react'
import { Link } from 'react-router-dom'

class NftGameplay extends React.Component {

  render() {

    return (
      <div
        align="center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '50vh',
          position: 'fixed'
        }}
      >
  
  <div className="glowing">
    <span className='pdr1'></span>
    
    <span className='pdr2'></span>
    
    <span className='pdr3'></span>
    
  </div>
  
  <div className="glowing">
    
    <span className='pdr1'></span>
    
    <span className='pdr2'></span>
    
    <span className='pdr3'></span>
    
  </div>
  
  <div className="glowing">
    
    <span className='pdr1'></span>
    
    <span className='pdr2'></span>
    
    <span className='pdr3'></span>
    
  </div>
  
  <div className="glowing">
    
    <span className='pdr1'></span>
    
    <span className='pdr2'></span>
    
    <span className='pdr3'></span>
    
  </div>
  <div className='textProgress'>
    <span className='prgs'>On Progress</span>
  </div>
  <Link
            to="/"
            style={{
              fontSize: '20px',
              textTransform: 'uppercase',
              letterSpacing: '2px',
              background: '#00bcd4',
              padding: '8px',
              borderRadius: '5px',
              color: '#ffff',
              textDecoration: 'none',
              position: 'fixed',
              top: '60%',
              width: '250px'
            }}
          >
            Go Back
          </Link>
      </div>
    )
  }
}
export default NftGameplay
